import {
  ProductionVolume,
  MappedProductionVolume
} from '../components/productionContainer/productionMaterials.types'
import {Material} from '../types'

import {getMaterialName} from './materials.utils'

export const filterProductionVolumes = (volumes: ProductionVolume[] = []) =>
  volumes.filter((volume) => volume.actualValue > 0)

/**
 * this function maps the values of ProductionVolumes and Material
 * @param volumes
 * @param materials
 * @returns MappedProductionVolume[]
 */
export const filterAndMapProductionVolumes = (
  volumes: ProductionVolume[] = [],
  materials: Material[]
) => {
  const filteredVolumes = filterProductionVolumes(volumes)

  const mappedVolumes = filteredVolumes.reduce(
    (acc: MappedProductionVolume[], volume: ProductionVolume) => {
      const materialName = getMaterialName(materials, volume)

      // add to existing volume if it already exists
      const existingVolume = acc.find((v) => v.materialName === materialName)
      if (existingVolume) {
        existingVolume.actualValue += volume.actualValue
        return acc
      } else {
        // create a new volume if it doesn't exist
        acc.push({...volume, materialName})
        return acc
      }
    },
    []
  )

  return mappedVolumes
}
