import {DateTime, TimeZone} from '@hconnect/uikit'
import {
  CollapsibleTableWithoutPagination,
  ColumnsConfig
} from '@hconnect/uikit/src/lib2/components/table'
import {Box, Tooltip} from '@mui/material'
import {isEqual} from 'lodash'
import moment from 'moment-timezone'
import React, {memo, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import CementIcon from '../../assets/cement.svg'
import ClinkerIcon from '../../assets/clinker.svg'
import {Material} from '../../types'
import {FilterTimeRange} from '../../types/timeAndDate.types'
import {filterAndMapProductionVolumes, formatNumber} from '../../utils'
import {ContentBox} from '../ContentBox'
import {DateTimeFormat} from '../dateTime/DateTimeFormat'
import {TimeFormat} from '../dateTime/TimeFormat'

import {LastUpdateAt, MappedProductionVolume, ProductionVolume} from './productionMaterials.types'

type Props = {
  contentMode: 'fullParentHeight' | 'max100PercentOfParentHeight' | 'contentDependentHeight'
  productionVolumes?: ProductionVolume[]
  isLoading: boolean
  timeRange: FilterTimeRange
  timezone: TimeZone
  materials?: Material[]
}

const getTooltipContent = (
  begin: DateTime | null,
  lastUpdateAt: LastUpdateAt,
  timezone: string
) => {
  return Object.keys(lastUpdateAt).map((millName) => {
    const endDate = lastUpdateAt[millName]
    const isDateSame = moment(begin).isSame(endDate, 'day')

    return (
      <Box key={millName}>
        {millName}: <DateTimeFormat date={begin} separator={' '} timezone={timezone} />
        {' - '}
        {isDateSame ? (
          <TimeFormat date={endDate} timezone={timezone} />
        ) : (
          <DateTimeFormat date={endDate} separator={' '} timezone={timezone} />
        )}
      </Box>
    )
  })
}

export const ProductionContainer: React.FC<Props> = memo(
  ({contentMode, productionVolumes = [], materials = [], isLoading, timeRange, timezone}) => {
    const {t, i18n} = useTranslation()
    const language = i18n.language
    // Note: production volumes with 0 actual value should be hidden
    const finalProductionVolumes = useMemo(
      () => filterAndMapProductionVolumes(productionVolumes, materials),
      [productionVolumes, materials]
    )

    const config: ColumnsConfig<MappedProductionVolume> = useMemo(
      () => [
        {
          key: 'cementType',
          label: t('productionVolume.label.material'),
          template: ({rowData}) => {
            return rowData.lastUpdatedAt ? (
              <Tooltip
                arrow
                title={getTooltipContent(timeRange.startDate, rowData.lastUpdatedAt, timezone)}
              >
                <Box display={'inline-flex'} pr={1}>
                  <Box
                    component={'img'}
                    sx={{mr: 1}}
                    src={rowData.isClinker ? ClinkerIcon : CementIcon}
                    alt={t(`summary.label.${rowData.isClinker ? 'clinker' : 'cement'}`)}
                  />
                  {rowData.materialName}
                </Box>
              </Tooltip>
            ) : (
              <Box display={'flex'} pr={1}>
                <Box
                  component={'img'}
                  sx={{mr: 1}}
                  src={rowData.isClinker ? ClinkerIcon : CementIcon}
                  alt={t(`summary.label.${rowData.isClinker ? 'clinker' : 'cement'}`)}
                />
                {rowData.materialName}
              </Box>
            )
          }
        },
        {
          key: 'productionVolume',
          label: t('productionVolume.label.actual'),
          template: ({rowData}) => (
            <>{`${formatNumber(Math.ceil(rowData.actualValue), language)} ${rowData.unit ?? ''}`}</>
          ),
          headerCellProps: {
            align: 'right'
          },
          cellProps: {
            align: 'right'
          }
        }
      ],
      [t, timeRange.startDate, timezone, language]
    )

    return (
      <ContentBox
        title={t('summary.label.ProductionVolumes')}
        mode={contentMode}
        bodyWithPadding
        minHeight="450px"
        data-test-id={'production-container'}
        isLoading={isLoading}
      >
        {productionVolumes?.length ? (
          <CollapsibleTableWithoutPagination<MappedProductionVolume>
            config={config}
            data={finalProductionVolumes}
          />
        ) : (
          !isLoading && t('summary.label.noData')
        )}
      </ContentBox>
    )
  },
  isEqual
)
