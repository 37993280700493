import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'

import {Iso8601, TaskPreviewData, TaskRepetition} from '../types'

import {useApi} from './useApi'

export const QueryKey = 'task-dueDate-preview'

export const useTaskRepetitionPreview = (
  repetition: TaskRepetition,
  itemsCount = 5,
  plantId: string,
  defaultStartDate?: Iso8601,
  options?: UseQueryOptions<Iso8601[], AxiosError>
) => {
  const {publicApi} = useApi()

  const path = `/shifthandover/${plantId}/tasks/duedatespreview`
  return useQuery<Iso8601[], AxiosError>(
    // refresh preview details on repetition or start date change
    [QueryKey, repetition, defaultStartDate, plantId],
    async () => {
      if (!repetition) return []

      const data: TaskPreviewData = {...repetition, itemsCount}
      if (defaultStartDate) {
        data.startDate = defaultStartDate
      }

      const response = await publicApi.post<Iso8601[]>(path, data)
      return response.data
    },
    {
      // hook specific options
      refetchOnMount: false,
      refetchOnWindowFocus: false,

      // allow the caller to override the default options
      ...options
    }
  )
}
