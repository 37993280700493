import {Box} from '@mui/material'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {getTranslationKey} from '../../utils/translation.utils'

import {RUNNING_TIME_COLOR} from './common'
import {LegendItem} from './LegendItem'

type OperatingHoursLegendListProps = {
  translationPrefix?: string
}

export const OperatingHoursLegendList: FC<OperatingHoursLegendListProps> = ({
  translationPrefix
}) => {
  const {t} = useTranslation()

  return (
    <Box display="flex" gap={1} mb={2} justifyContent="flex-end">
      <LegendItem
        color={RUNNING_TIME_COLOR.running}
        label={t(getTranslationKey('runningTime.legend.running', translationPrefix))}
      />
      <LegendItem
        color={RUNNING_TIME_COLOR.stopped}
        label={t(getTranslationKey('runningTime.legend.unplannedStoppage', translationPrefix))}
      />
      <LegendItem
        color={RUNNING_TIME_COLOR.planned}
        label={t(getTranslationKey('runningTime.legend.plannedStoppage', translationPrefix))}
      />
    </Box>
  )
}
