import type {Iso8601} from '@hconnect/uikit'
import {tail} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useTaskRepetitionPreview} from '../../hooks/useTaskRepetitionPreview'
import {TaskRepetition} from '../../types'
import {getTranslationKey} from '../../utils/translation.utils'

import {TaskRepetitionPreview} from './TaskRepetitionPreview'

type Props = {
  settings: TaskRepetition
  defaultStartDate?: Iso8601
  omitFirstOccurrence?: boolean
  plantId: string
  timezone: string
  translationPrefix?: string
}

export const TaskRepetitionLabel: React.FC<Props> = ({
  settings,
  defaultStartDate,
  omitFirstOccurrence,
  plantId,
  timezone,
  translationPrefix
}) => {
  const {t} = useTranslation()

  const numberOfPreviewItems = 3
  const query = useTaskRepetitionPreview(
    settings,
    numberOfPreviewItems + (omitFirstOccurrence ? 2 : 1),
    plantId,
    defaultStartDate
  )

  if (!query.data) {
    return <span>{t(getTranslationKey('recurringDialog.calculating', translationPrefix))}</span>
  }

  if (query.error) {
    return <span>{t(getTranslationKey('error.general', translationPrefix))}</span>
  }

  const dates = omitFirstOccurrence ? tail(query.data) : query.data

  return (
    <TaskRepetitionPreview
      dates={dates}
      repetition={settings.repetition}
      timezone={timezone}
      weekDays={'weekDays' in settings ? settings.weekDays : undefined}
      numberOfPreviewItems={numberOfPreviewItems}
    />
  )
}
