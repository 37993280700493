import {isArray} from 'lodash'

import {ProductionVolume} from '../components/productionContainer/productionMaterials.types'
import {Material} from '../types'

export const getMaterialName = (
  materials: Material[],
  productionVolume: ProductionVolume
): string => {
  const {cementType} = productionVolume

  const mappedMaterial = materials.find((material) => {
    const {pxTrendCounters} = material
    if (isArray(pxTrendCounters)) return pxTrendCounters.includes(cementType)
    return pxTrendCounters === cementType
  })

  return mappedMaterial ? mappedMaterial.name : cementType
}
